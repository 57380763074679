import React from "react";
import LogoEvent from "../../assets/imgEvents/LogoEvento.png";
import { FaMapMarkerAlt } from "react-icons/fa";
import "./styleEventCard.css";

const EventCard = ({ dataEvents }) => {
  return (
    <>
      <div className="events-target-info">
        <div className="image-container-event">
          <img
            src={dataEvents.img} alt={dataEvents.title}
            className="target-image-event"
          />
        </div>
        <div className="info-container-Event" style={{ textAlign: 'center' }}>
        <h3 className="target-title">{dataEvents.title}</h3>
          <p className="target-address">
            {dataEvents.date}
          </p>
        </div>
      </div>
    </>
  );
};

export default EventCard;

