import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import logo from "../../assets/imgMenu/logo_WA_Mexico1.png";
import { FaBars, FaTimes } from "react-icons/fa"; // Importa los iconos de react-icons
import "./navbar.css";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  let marker = document.querySelector('#marker');

  useEffect(() => {
    currentPage()
  }, [activeLink]);

  const currentPage = () => {
    const activeElement = document.querySelector('.active');
    if (activeElement) {
      marker.style.left = `${activeElement.offsetLeft}px`;
      marker.style.width = `${activeElement.offsetWidth}px`;
    } else {
      marker = document.querySelector('#marker');
      marker.style.left = `0px`;
      marker.style.width = `0px`;
    }
  }

  const indicator = (e) => {
    if(!marker) {
      marker = document.querySelector('#marker');
    }
    marker.style.left = `${e.currentTarget.offsetLeft}px`;
    marker.style.width = `${e.currentTarget.offsetWidth}px`;
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = (link, path) => {
    setActiveLink(link);
    setMenuOpen(false);
    navigate(path); // Navega a la ruta especificada
  };

  // Verificar la ruta actual y establecer el activeLink correspondiente
  useEffect(() => {
    const path = location.pathname;

    switch (path) {
      // case "/":
      //   setActiveLink("deporte");
      //   break;
      case "/athletes":
        setActiveLink("atletas");
        break;
      case "/coaches":
        setActiveLink("coaches");
        break;
      case "/events":
        setActiveLink("eventos");
        break;
      case "/fields":
        setActiveLink("campos");
        break;
      case "/records":
        setActiveLink("records");
        break;
      case "/executive-board":
        setActiveLink("board");
        break;
      case "/contact-us":
        setActiveLink("contacto");
        break;
      // case "/about-us":
      //   setActiveLink("nosotros");
      //   break;
      default:
        setActiveLink(""); // Restablecer si la ruta no coincide
    }
  }, [location.pathname, activeLink]); // Se ejecuta cada vez que cambia la ruta

  const renderPages = (
    <ul>
      <div id="marker"></div>
      <li   
        className={activeLink === "atletas" ? "active" : "textos"} 
        onMouseOver={(e) => indicator(e)}
        onMouseLeave={currentPage}
      >
        <p onClick={() => handleLinkClick("atletas", "/athletes")}>Atletas</p>
      </li>
      <li 
        className={activeLink === "coaches" ? "active" : "textos"} 
        onMouseOver={(e) => indicator(e)}
        onMouseLeave={currentPage}
      >
        <p onClick={() => handleLinkClick("coaches", "/coaches")}>Entrenadores</p>
      </li>
      <li 
        className={activeLink === "eventos" ? "active" : "textos"} 
        onMouseOver={(e) => indicator(e)}
        onMouseLeave={currentPage}
      >
        <p onClick={() => handleLinkClick("eventos", "/events")}>Eventos</p>
      </li>
      <li 
        className={activeLink === "campos" ? "active" : "textos"} 
        onMouseOver={(e) => indicator(e)}
        onMouseLeave={currentPage}
      >
        <p onClick={() => handleLinkClick("campos", "/fields")}>Campos</p>
      </li>
      <li 
        className={activeLink === "records" ? "active" : "textos"} 
        onMouseOver={(e) => indicator(e)}
        onMouseLeave={currentPage}
      >
        <p onClick={() => handleLinkClick("records", "/records")}>Records</p>
      </li>
      <li 
        className={activeLink === "board" ? "active" : "textos"} 
        onMouseOver={(e) => indicator(e)}
        onMouseLeave={currentPage}
      >
        <p onClick={() => handleLinkClick("board", "/executive-board")}>Consejo Directivo</p>
      </li>
      <li 
        className={activeLink === "contacto" ? "active" : "textos"} 
        onMouseOver={(e) => indicator(e)}
        onMouseLeave={currentPage}
      >
        <p onClick={() => handleLinkClick("contacto", "/contact-us")}>Contacto</p>
      </li>
    </ul>
  )

  return (
    <>
      <nav>
        <div className="container contNav">
          <div className="logoNav">
            <div className="contImgNav">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
          </div>

          <div className={`textContainerNav ${menuOpen ? "open" : ""}`}>
            {renderPages}
          </div>

          <div className="menuIcon" onClick={toggleMenu}>
            <FaBars className="icon" />
          </div>
        </div>

      </nav>


      <div className={`nav-container ${menuOpen ? "active" : ""}` }>
        <div className="close-menu-icon"><FaTimes className="icon" onClick={toggleMenu} /></div>
        <div className="logo-mb">
          <img onClick={() => handleLinkClick("home", "/")} src={logo} alt="logo" />
        </div>

        <div className="btns-container-nav">
          {renderPages}
        </div>

      </div>
    </>
  );
};

export default NavBar;