import React from "react";
import "./main-home.css";

function Proximamente() {
  return (
    <>
      <div className="home-container">
      <div className="img-home-container d-flex justify-content-center align-items-center">

        <h1>Próximamente</h1>
      </div>
        
      </div>
      </>
  );
}

export default Proximamente;
