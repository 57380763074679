import './athletes-target-info.css';
import iconStatus from '../../assets/imgAthletes/icon-status-green.png';
import profileNull from "../../assets/imgAthletes/userGray.png"

function AthletesTargetInfo({ dataAthlete }) {

  return (
    <div className='target-athlete-info'>
      <div className='image-container'>
        <img 
          className='athlete-card' 
          src={dataAthlete.photo} 
          alt={`${dataAthlete.name} ${dataAthlete.last_name}`} />
          {
            dataAthlete.status === "1" && (
              <img className='icon-association' src={dataAthlete.association_logo} alt='' />
            )
          }
      </div>

      <div className='information'>
        <h6>
          {`${dataAthlete.name} ${dataAthlete.last_name}`}
          {dataAthlete.status === "1" && (
            <img className="icon" src={iconStatus} alt="" />
          )}
        </h6>
        <div className='d-flex justify-content-between'>
            <p>Edad: {dataAthlete.birthday} años</p>
            <p>{"Atleta"}</p>
        </div>
        <button className='first'>{ dataAthlete.association_state }</button>
        <button className='second'>{ dataAthlete.club_name }</button>
        <button className='third'>
          {dataAthlete.id_modality.map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </button>
      </div>
    </div>
  )
}

export default AthletesTargetInfo;