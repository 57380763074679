import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import NavBar from "../../Components/navbar/navbar";
import Footer from "../../Components/footer/footer";
import Acordion from "../../Components/acordion/Acordion";
import EventCard from "../../Components/EventCard/EventCard";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./styles.css";
import { IoIosArrowDown } from "react-icons/io";
import Slider from "react-slick";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";

const Events = () => {
  const [selectedDiscipline, setSelectedDiscipline] = useState("");
  const [selectedState, setSelectedState] = useState("all");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [dataEvent, setDataEvent] = useState([]);

  useEffect(() => {
    // Función para obtener los datos de la API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_BACK}/0a2b15b1a0d8d09e86d4b42d2012a091/events`
        );
        setDataEvent(response.data.data); // Asume que los eventos están en response.data.data
      } catch (error) {
        console.error("Error al obtener los datos de la API:", error);
      }
    };

    fetchData(); // Llamada a la función para obtener los datos
  }, []);

  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(".ourEvents");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  // Componente para la flecha derecha del slider
  function SampleNextArrow({ onClick }) {
    return (
      <div className="arrow-Event arrow-right-Event" onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  }

  // Componente para la flecha izquierda del slider
  function SamplePrevArrow({ onClick }) {
    return (
      <div className="arrow-Event arrow-left-Event" onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleDateChangeStart = (e) => {
    setSelectedStartDate(e.target.value);
  };

  const handleDateChangeEnd = (e) => {
    setSelectedEndDate(e.target.value);
  };

  const handleDisciplineChange = (e) => {
    setSelectedDiscipline(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const filteredEvents = dataEvent.filter((event) => {
    const disciplineArray = event.discipline.split(",");

    // Obtener fechas del evento (ya están en formato YYYY-MM-DD)
    const eventStartDate = event.date_start;
    const eventEndDate = event.date_finish;

    // Obtener las fechas seleccionadas del filtro
    const filterStartDate = selectedStartDate ? selectedStartDate : null;
    const filterEndDate = selectedEndDate ? selectedEndDate : null;

    // Lógica de comparación de fechas en formato string (YYYY-MM-DD)
    const isWithinDateRange =
      (!filterStartDate || eventStartDate >= filterStartDate) &&
      (!filterEndDate || eventEndDate <= filterEndDate);

    return (
      (selectedDiscipline === "" ||
        disciplineArray.includes(selectedDiscipline)) &&
      (selectedState === "all" || event.state.name === selectedState) &&
      isWithinDateRange // Filtrar por rango de fechas
    );
  });

  const handleInputsClear = () => {
    setSelectedDiscipline("");
    setSelectedState('all');
    setSelectedStartDate("");
    setSelectedEndDate("");
  }

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>Eventos | WA México</title>
        <meta name="description" content="Mantente al día con los eventos deportivos organizados por World Archery México." />
      </Helmet>
      <NavBar />
      <section className="contOurEvents">
        <div className="events-title">
          <h1>Eventos</h1>
        </div>

        <div className="contentParrafoEvents">
          <p>
            Ya sea que desees competir o disfrutar como espectador, aquí puedes
            encontrar la información de nuestros eventos.
          </p>
        </div>

        <div>
          <a
            href="#"
            className="scroll-down"
            date="true"
            onClick={handleScroll}
          >
            <IoIosArrowDown className="scroll-down-arrow" />
          </a>
        </div>
      </section>

      <section className="ourEvents d-none d-md-block" id="ourEvents">
        <div className="our_events_title mb-4">
          <h2>Eventos Destacados</h2>
        </div>
        <div className="container">
          <Slider {...settings}>
            {dataEvent.map((event, index) => (
              <div key={index} className="d-flex justify-content-center">
                <EventCard
                  dataEvents={{
                    img: event.logo,
                    title: event.name,
                    date: `${new Date(
                      event.date_start
                    ).toLocaleDateString()} - ${new Date(
                      event.date_finish
                    ).toLocaleDateString()}`,
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <div className="container">
        <div className="search-inputs-container mt-4 mb-4">
          <div className="input-container">
            <select
              className="form-control input-select"
              value={selectedDiscipline}
              onChange={handleDisciplineChange}
            >
              <option value="">
                Disciplina: Todas{setSelectedDiscipline || "Seleccionar"}
              </option>
              <option value="1">Outdoor</option>
              <option value="2">Indoor</option>
              <option value="3">3-D</option>
              <option value="4">Field</option>
            </select>
            <div className="input-select-icon">
              <IoIosArrowDown />
            </div>
          </div>

          <div className="input-container">
            <select
              className="form-control input-select"
              value={selectedState}
              onChange={handleStateChange}
            >
              <option value="all">
                Estado: Todos {setSelectedState || "Selecciona"}
              </option>
              <option value="Aguascalientes">Aguascalientes</option>
              <option value="Baja California">Baja California</option>
              <option value="Baja California Sur">Baja California Sur</option>
              <option value="Campeche">Campeche</option>
              <option value="Chiapas">Chiapas</option>
              <option value="Chihuahua">Chihuahua</option>
              <option value="Ciudad de México">Ciudad de México</option>
              <option value="Coahuila">Coahuila</option>
              <option value="Colima">Colima</option>
              <option value="Durango">Durango</option>
              <option value="Estado de México">Estado de México</option>
              <option value="Guanajuato">Guanajuato</option>
              <option value="Guerrero">Guerrero</option>
              <option value="Hidalgo">Hidalgo</option>
              <option value="Jalisco">Jalisco</option>
              <option value="Michoacán">Michoacán</option>
              <option value="Morelos">Morelos</option>
              <option value="Nayarit">Nayarit</option>
              <option value="Nuevo León">Nuevo León</option>
              <option value="Oaxaca">Oaxaca</option>
              <option value="Puebla">Puebla</option>
              <option value="Querétaro">Querétaro</option>
              <option value="Quintana Roo">Quintana Roo</option>
              <option value="San Luis Potosí">San Luis Potosí</option>
              <option value="Sinaloa">Sinaloa</option>
              <option value="Sonora">Sonora</option>
              <option value="Tabasco">Tabasco</option>
              <option value="Tamaulipas">Tamaulipas</option>
              <option value="Tlaxcala">Tlaxcala</option>
              <option value="Veracruz">Veracruz</option>
              <option value="Yucatán">Yucatán</option>
              <option value="Zacatecas">Zacatecas</option>
              <option value="Universidad Nacional Autónoma de México">UNAM</option>
              <option value="Instituto Politécnico Nacional">IPN</option>
            </select>
            <div className="input-select-icon">
              <IoIosArrowDown />
            </div>
          </div>

          <div className="input-container">
            <input
              type="date"
              name="start-date"
              id="start-date"
              className="form-control input-with-icon date-icon input-text"
              value={selectedStartDate}
              onChange={handleDateChangeStart}
            />
          </div>

          <div className="input-container">
            <input
              type="date"
              name="end-date"
              id="end-date"
              className="form-control input-with-icon date-icon input-text"
              value={selectedEndDate}
              onChange={handleDateChangeEnd}
            />
          </div>

          <div className="input-container">
            <div className="reset-btn">
              <button type="button" onClick={handleInputsClear}>Borrar Filtros</button>
            </div>
          </div>
        </div>
      </div>

      <section className="events-acordions pb-4">
        <div className="container">
          {/* <Acordion /> */}
          <div className="event-acordion">
            {filteredEvents.length === 0 ? (
              <h4 className="text-center">No se encontraron Eventos</h4>
            ) : (
              filteredEvents
                .slice(0)
                .map((event, index) => (
                  <Acordion key={index} dataEvents={event} />
                ))
            )}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Events;
